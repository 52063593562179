export const copy = o => JSON.parse(JSON.stringify(o))
export const inMobile = /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)

export function debounce(fn, delay = 500) {
	var ctx;
	var args;
	var timer = null;

	var later = function() {
		fn.apply(ctx, args);
		timer = null;
	};

	return function() {
		ctx = this;
		args = arguments;
		if (timer) {
			clearTimeout(timer);
			timer = null;
		}
		timer = setTimeout(later, delay);
	};
}

export function throttle(func, delay = 500) {
	var prev = Date.now();
	return function() {
		var context = this;
		var args = arguments;
		var now = Date.now();
		if (now - prev >= delay) {
			func.apply(context, args);
			prev = Date.now();
		}
	}
}