<template>
	<div class="footer">
		<div class="wrapper">
			Copyright©2023 06 THỂ THAO.Rights Reserved |
			<router-link to="/aboutus" class="link">{{ $t('about006Live') }}</router-link>
			|
			<router-link to="/disclaimer" class="link">{{ $t('disclaimer') }}</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'wsFooter'
};
</script>

<style lang="less"></style>
