import moment from 'moment'

const defaultLogos = ['']
for (let i = 1; i < 9; i++) {
	defaultLogos.push(require(`@/assets/img/default_type_${i}.png`))
}

export default {
	data() {
		return {
			isDev: /dev-/.test(location.hostname) || location.hostname == 'localhost'
		}
	},
	methods: {
		onCoverError(e) {
			e.target.src = require('@/assets/img/default_cover.png')
		},
		onAvatarError(e) {
			e.target.src = require('@/assets/img/default_avatar.png')
		},
		onTeamLogoError(e, type) {
			if (defaultLogos[type] && e.target.src !== defaultLogos[type]) {
				e.target.src = defaultLogos[type]
			}
		},
		makeMatchItem(item) {
			const {
				type,
				cover,
				id,
				league_name_zh,
				home_team_zh,
				away_team_zh,
				tournament_id,
				tournament_type,
				member_id,
				match_time_int,
				start_time_int
			} = item;

			if (id) {
				item.cover_p = cover;
				item.type = "reply";
				item.href = `/reply/${id}`;
				item.url = item.video_url
			} else {
				item.time = moment((match_time_int || start_time_int) * 1000).format("MM-DD HH:mm");
				item.href = `/detail/${tournament_type || type}/${tournament_id}/${member_id}`;
				item.title = `${league_name_zh || ''} ${home_team_zh} vs ${away_team_zh}`;
			}
			return item
		}
	}
}
