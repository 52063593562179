<template>
	<div class="player-container">
		<div id="dplayer" class="player" ref="dplayer"></div>
		<div v-if="player.status > 0" :class="['status', 'status-' + player.status]"></div>
	</div>
</template>
<script>
import moment from 'moment';
import { PLAYER_STATUS } from '@/utils/constants.js';

var reinitTimer = null;
var flvPlayer = null

export default {
	name: 'wsPlayer',
	components: {},
	data() {
		return {
			player: {
				instance: null,
				tryCount: 20,
				status: PLAYER_STATUS.LOADING
			},
			match_state: 99,
			match_start: 0
		};
	},
	methods: {
		initPlayer(match) {

  
			if (reinitTimer) {
				clearTimeout(reinitTimer);
			}
			if (this.player.instance) {
				try {
					this.player.instance.destroy();
				} catch (e) { }
			}
			if (/safari/i.test(navigator.userAgent) && !/chrome/i.test(navigator.userAgent)) {
				match.url = match.stream || match.url
			}
			this.match_state = match.state
			this.match_start = match.match_time_int || match.start_time_int
			this.player.tryCount = this.match_state == -1 ? 5 : 15
			this.player.status = undefined;
			if (/\.mp4/i.test(match.url)) {
				this.initReplyPlayer(match);
				// } else if (match.state == -1) {
				//     this.player.status = PLAYER_STATUS.ENDED;
				//     return;
			} else if (match.state == 0 && this.match_start - moment().unix() > 3600) {
				this.player.status = PLAYER_STATUS.NOSTART;
			} else if (/\.m3u8|\.flv/.test(match.url)) {
				this.initLivePlayer(match);
			} else if (!match.tournament_id) {
				this.player.status = PLAYER_STATUS.NOLIVING;
			} else {
				this.player.status = PLAYER_STATUS.NOSIGN;
			}
		},
		initReplyPlayer(match) {
			if (this.player.instance) {
				try {
					this.player.instance.dispose();
				} catch (e) { }
			}
			const config = {
				container: document.getElementById('dplayer'),
				live: false,
				autoplay: true,
				lang: 'en',
				contextmenu: [],
				video: {
					url: match.url,
					type: 'auto',
					pic: require('../assets/img/default_poster.png'),
				}
			}
			new DPlayer(config);
		},
		initLivePlayer(match) {
			if (this.player.instance) {
				try {
					this.player.instance.destroy();
				} catch (e) { }
			}
			const config = {
				container: document.getElementById('dplayer'),
				live: true,
				autoplay: false,
				lang: 'en',
				contextmenu: [],
				video: {
					url: match.url,
					type: 'auto',
					pic: require('../assets/img/default_poster.png'),
				}
			}
			if (/\.flv/i.test(match.url)) {
				const vm = this
				config.video = {
					url: match.url,
					type: 'customFlv',
					customType: {
						customFlv: (video, player) => {
							if (flvPlayer) {
								try {
									flvPlayer.destroy()
									flvPlayer = null
								} catch (e) { }
							}
							flvPlayer = flvjs.createPlayer({
								type: 'flv',
								url: video.src,
							});
							flvPlayer.on(flvjs.Events.ERROR, (e) => {
								console.info('player.flvjs.error', e)
								vm.onPlayerError(e, match)
							})
							flvPlayer.attachMediaElement(video);
							flvPlayer.load();
						},
					},
				}
			}
			console.info('player.config', config)
			const player = new DPlayer(config);
			player.on('playing', (e) => {
				console.info('player.playing', e)
				this.player.tryCount = 15;
				this.player.status = PLAYER_STATUS.PLAYING;
			})
			player.on('canplay', (e) => {
				console.info('player.canplay', e)
				this.player.status = PLAYER_STATUS.LOADING
				player.play()
			})
			player.on('error', (e) => {
				this.onPlayerError(e, match)
			})
			player.on('ended', (e) => {
				this.onPlayerError(e, match)
			})
			console.info('player.instance', player)
			this.player.instance = player;
		},
		onPlayerError(e, match) {
			console.info('player.error', e)
			const tryCount = --this.player.tryCount;
			let delay = 0;
			if (tryCount > 10) {
				delay = 500;
			} else if (tryCount > 5) {
				this.player.status = PLAYER_STATUS.ERROR;
				delay = 2000;
			} else if (tryCount > 0) {
				this.player.status = this.match_state == -1 ? PLAYER_STATUS.ENDED : PLAYER_STATUS.ERROR;
				delay = 20000;
			} else {
				this.player.status = this.match_state == -1 ? PLAYER_STATUS.ENDED : PLAYER_STATUS.ERROR;
				delay = 60000;
			}
			if (this.match_start - moment().unix() > 60) {
				this.player.status = PLAYER_STATUS.NOSTART
				delay = 120000;
			}
			// console.info('player.reinitTimer', tryCount, delay)
			// reinitTimer && clearTimeout(reinitTimer)
			// reinitTimer = setTimeout(() => {
			// 	this.initLivePlayer(match);
			// }, delay);
		}
	}
};
</script>
<style lang="less">
.player-container,
.dplayer {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: #2b2b2b;

	.player {
		z-index: 1;
	}

	.status {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		// bottom: 50px;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: #2b2b2b;

		&.status-1 {
			background-image: url(../assets/img/state_error.png);
			background-size: 204px 234px;
		}

		&.status-2 {
			background-image: url(../assets/img/state_0.png);
			background-size: 204px 234px;
		}

		&.status-3 {
			background-image: url(../assets/img/state_error.png);
			background-size: 204px 234px;
		}

		&.status-4 {
			background-image: url(../assets/img/state_-1.png);
			background-size: 204px 234px;
		}

		&.status-5 {
			background-image: url(../assets/img/state_2048.png);
			background-size: 204px 234px;
		}

		&.status-6 {
			background-color: transparent;
			background-image: url(../assets/img/loading.gif);
			background-position: 40px 40px;
			background-size: 40px 40px;
		}

		&.status-7 {
			background-color: transparent;
			background-image: url(../assets/img/state_1024.png);
			background-position: center center;
			background-size: 340px 190px;
		}
	}
}

.player-mute-button {
	float: right;
	margin-right: 15px;
	margin-top: 12px;
	color: #ffffff;
	cursor: pointer;
}

.dplayer-menu {
	display: none !important;
}
</style>