import api from '@/api'
import store from '@/store'
import OSS from "ali-oss";
import bus from '@/utils/bus.js'
import {
	message
} from 'ant-design-vue';

export const follow = async (anchor = {}) => {
	const token = store.state.token
	if (!token) {
		return bus.$emit('login')
	}
	const {
		uid,
		is_follow
	} = anchor;
	const data = {
		uid,
		is_follow: is_follow == 1 ? 0 : 1
	};
	const response = await api.post(`/web/follow_anchor`, data);
	if (response && response.code == 200) {
		Object.assign(anchor, data);
		message.success(this.$t('succeedToOperate'))
	}
}
