<template>
	<div class="wrapper">
		<div class="main-section">
			<div class="content">
				<div class="auchor">
					<img class="avatar" :src="match.face || '0'" @error="onAvatarError" />
					<div class="info">
						<div class="title" v-if="match.match_time_int">
							<span class="league">{{ match.league_name_zh }}</span>
							<span class="time">{{ match.match_time || match.start_time }}</span>
						</div>
						<div class="meta">
							<span class="nickname">{{ match.nickname || match.username }}</span>
							<span style="flex:1"></span>
							<span class="viewer">{{ match.viewer || match.num || 0 | kindlyNumber }}</span>
						</div>
					</div>
					<span class="team" v-if="match.match_time_int">
						<span class="teamname" style="text-align: right;">{{ match.home_team_zh }}</span>
						<img :src="match.home_logo || '0'" @error="onTeamLogoError($event, match.type)" />
						<span style="color:#ED4B65">VS</span>
						<img :src="match.away_logo || '0'" @error="onTeamLogoError($event, match.type)" />
						<span class="teamname">{{ match.away_team_zh }}</span>
					</span>
					<div class="extrainfo" v-if="minfo.uid != match.uid">
						<a :class="['follow', match.is_follow == 1 ? 'active' : '']" href="javascript:;" @click="onFollow">关注</a>
						<a-popover placement="bottom" v-if="!!match.qrcode">
							<template slot="content">
								<div class="popover-qrcode">
									<img :src="match.qrcode" />
									<span>
							    	{{ $t('scan') }}
										<br />
										{{ $t('addStreamers') }}
									</span>
								</div>
							</template>
							<img class="qrcode" src="../assets/img/icon-qrcode.png" />
						</a-popover>
					</div>
				</div>
				<div class="player-wrapper"><ws-player ref="player" /></div>
			</div>
			<div class="aside"><ws-chatroom :match="match" :anchor_uid="match.uid" ref="chatroom"></ws-chatroom></div>
		</div>

		<div class="section" v-if="schedules.length > 0">
			<div class="section-header">{{ $t('streamerSchedules') }}</div>
			<div class="section-swiper">
				<div class="schedules">
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide schedule-item" v-for="(item, index) in schedules" :key="index">
								<div class="meta">
									<img v-if="item.type == 1" src="../assets/img/icon-schedule-1.png" />
									<img v-else-if="item.type == 2" src="../assets/img/icon-schedule-2.png" />
									<span class="league">{{ item.league_name_zh }}</span>
									{{ ((item.match_time_int || item.start_time_int) * 1000) | dateFormat('HH:mm DD-MM') }}
								</div>
								<div class="teamname">
									<img :src="item.home_logo" @error="onTeamLogoError($event, item.tournament_type || item.type)" />
									<span>{{ item.home_team_zh }}</span>
								</div>	
								<div class="teamname">
									<img :src="item.away_logo" @error="onTeamLogoError($event, item.tournament_type || item.type)" />
									<span>{{ item.away_team_zh }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section" v-if="recommends.length > 0">
			<div class="section-header">{{ $t('videoRecommends') }}</div>
			<div class="section-body"><ws-match-item v-for="(item, index) in recommends" :key="index" :item="item"></ws-match-item></div>
		</div>

		<div class="tips">
        {{ $t('specialClaims') }}
			<br />
        {{ $t('specialClaimsContent1') }}
        <br/>
        {{ $t('specialClaimsContent2') }}
		</div>
	</div>
</template>

<script>
import wsPlayer from '@/components/wsPlayer.vue';
import wsMatchItem from '@/components/wsMatchItem.vue';
import wsChatroom from '@/components/wsChatroom.vue';
import { follow } from '@/utils/fn.js';
import { mapState } from 'vuex';
export default {
	name: 'Home',
	components: {
		wsMatchItem,
		wsPlayer,
		wsChatroom
	},
	data() {
		return {
			match: {},
			more: [],

			schedules: [],
			recommends: []
		};
	},
	computed: mapState(['minfo']),
	beforeRouteUpdate(to, from, next) {
		this.$loading();
		this.getLiveInfo(to.params);
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$loading();
			vm.getLiveInfo(to.params);
		});
	},
	created() {
		this.$bus.$on('refresh', () => {
			location.reload();
		});
	},
	methods: {
		async getLiveInfo(route) {
			const { tournament_type, tournament_id, member_id, uid } = route;
			var response = null;
			if (uid) {
				response = await this.$api.get('/web/anchor/live/detail?uid=' + uid);
			} else if (tournament_type && tournament_id && member_id) {
				response = await this.$api.get(`/web/live_lists/${tournament_type}/detail/${tournament_id}?member_id=${member_id}`);
			}
			if (response && response.code == 200) {
				const { more, detail = {} } = response.data;
				detail.code = detail.username;
				detail.username = detail.nickname;
				detail.is_follow = detail.is_follow || 0;
				this.match = detail;
				this.more = (more || [])
					.filter(v => v.member_id != detail.member_id)
					.map(item => {
						return this.makeMatchItem(Object.assign({}, detail, item));
					});
				this.$refs.player.initPlayer(detail);
				detail.room_status == 1 && detail.roomid && this.$refs.chatroom.init(detail.roomid);
				if (member_id || detail.member_id) {
					this.getAnchorSchedule(member_id || detail.member_id);
				}
			}

			this.getLives();
			this.$nextTick(() => {
				this.$loadend();
			});
		},
		async getAnchorSchedule(member_id) {
			const response = await this.$api.get(`/web/anchor_schedule`, {
				params: { member_id }
			});
			if (response && response.code == 200) {
				this.schedules = response.data;
			}
		},
		async onFollow() {
			if (this.match.is_follow == 1) {
				this.$modal.confirm({
            title: this.$t('unFollowReminder'),
					  okType: 'danger',
            okText: this.$t('unFollow'),
            cancelText: this.$t('thinkAgain'),
				  	onOk: () => {
					  	follow(this.match);
					  }
				});
			} else {
				follow(this.match);
			}
		},
		async getLives() {
			const response = await this.$api.get(`/web/live_lists/1`);
			if (response && response.code == 200) {
				this.recommends = response.data.data.map(this.makeMatchItem);
			}
			this.$nextTick(() => {
				this.$loadend();
			});
		}
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/live.less');
.popover-qrcode {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	color: #333333;
	text-align: center;
	img {
		width: 84px;
		height: 84px;
		margin-bottom: 10px;
	}
}
</style>
