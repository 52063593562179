<template>
    <div class="wrapper">
        <div class="ucenter">
            <aside>
                <div class="aside-header"> {{ $t('personalCenter') }}</div>
                <nav>
                    <router-link :class="{ active: routeName == 'UserInfo' }" to="/user/info">
                        <span class="icon icon-center"></span>
                        {{ $t('myFiles') }}
                    </router-link>
                    <router-link :class="{ active: routeName == 'UserFollow' }" to="/user/follow">
                        <span class="icon icon-follow"></span>
                        {{ $t('myFollows') }}
                    </router-link>
                    <router-link :class="{ active: routeName == 'UserAppointment' }" to="/user/appointment">
                        <span class="icon icon-appointment"></span>
                        {{ $t('myAppointments') }}
                    </router-link>
                    <router-link :class="{ active: routeName == 'UserFeedback' || routeName == 'UserFeedbackCreate' }"
                                 to="/user/feedback">
                        <span class="icon icon-feedback"></span>
                        {{ $t('myFeedbacks') }}
                    </router-link>
                </nav>
            </aside>
            <main>
                <div class="main-header">
                    <span class="title">       {{ $t($route.meta.title) }}</span>
                    <router-link v-if="routeName == 'UserFeedback'" class="button" to="/user/feedback/create">       {{ $t('toFeedback') }}
                    </router-link>
                </div>
                <router-view class="main-body"></router-view>
            </main>
        </div>
    </div>
</template>

<script>
export default {
    name: 'User',
    computed: {
        routeName() {
            return this.$route.name;
        }
    },
    mounted() {
        if (!this.$storage.get('token')) {
            location.href = '/';
        }
        this.$loadend();
    }
};
</script>

<style lang="less">
@import url('~@/assets/less/ucenter.less');
</style>
