<template>
    <div class="wrapper">
        <div class="news-tab">
            <ul>
                <li :class="acitve == 0 ? 'acitve' : ''" @click="typeChange(0)">tất cả</li>
                <li v-for='item in cateData' :class="item.type == acitve ? 'acitve' : ''"
                    @click="typeChange(item.type)">
                    {{ item.name }}</li>
            </ul>
        </div>
        <div>
            <div class="news-item" v-for="item in newsData" @click="goDetail(item.id)">
                <img src="../assets/img/default_cover.png" alt="" v-if="/hiqiu-news\.png/.test(item.face)">
                <img :src="item.face" alt="" v-else>
                <div class="info">
                    <h3>{{ item.title }}</h3>
                    <p class="desc">{{ item.desc }}</p>
                    <div class="plays-info">
                        {{ item.ctime * 1000 | dateFormat('HH:mm:ss DD-MM-YYYY') }}
                    </div>
                </div>
            </div>
            <div v-if="newsData.length <= 0" class="noData">
                Không có dữ liệu
            </div>
            <div class="pagination">
                <a-pagination class="pagination" :current="page" :total="count" :page-size="10"
                    @change="onPageChange" />
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'News',
    data() {
        return {
            cateData: [],
            newsData: [],
            acitve: 0,
            page: 1,
            count: 0,
        }
    },
    methods: {
        async getCate() {
            const response = await this.$api.get('/news/cate');
            if (response && response.code == 200) {
                // default_cover.png
                this.cateData = response.data
            }
        },
        async getNews() {
            this.$loading();
            const response = await this.$api.get(`/web/news?type=${this.acitve}&page=${this.page}`);
            if (response && response.code == 200) {
                this.newsData = response.data.list || []
                this.count = response.data.count
            }
            this.$loadend();
        },
        goDetail(id) {
            window.open(`/news/detail/${id}`)
        },
        typeChange(type) {
            this.acitve = type
            this.getNews()
        },
        onPageChange(page) {
            this.page = page
            this.getNews()
        }
    },
    mounted() {
        this.getCate()
        this.getNews()
    }
}
</script>
<style lang="less">
@import url('~@/assets/less/news.less');
</style>