<template>
    <div class="wrapper">
        <div class="news-detail">
            <h2>{{newsDetail.title}}</h2>
            <div>
                <div class="time">
                    {{$t('released')}} : {{newsDetail.ctime*1000 | dateFormat('HH:mm:ss DD-MM-YYYY')}}
                </div>
                <!-- <img :src="newsDetail.face" alt=""> -->
                <div class="news-content" v-html="newsDetail.content">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'newsDetail',
        data() {
            return {    
                newsDetail:{}
            }
        },
        methods: {
            async getNewsDetail(){
                const id = this.$route.params.id
                const response = await this.$api.get(`news/detail/${id}`);
                if (response && response.code == 200) {
                    this.newsDetail = response.data
                }
            },
        },
        mounted() {
            this.getNewsDetail()
        }
    }
</script>
<style lang="less">
    @import url('~@/assets/less/news.less');
</style>