
export default {
    frontPage: 'Trang Chủ',
    allLive: 'Toàn Bộ',
    schedules: 'Lịch Thi Đấu',
    app: 'Tải App',
    startLive: 'Bắt đầu Live',
    login: 'Đăng nhập',
    register: 'Đăng ký',
    logout: 'Đăng xuất',
    reset: 'reset',
    personalCenter: 'Trang cá nhân',
    myFollows: 'Trọng tâm của tôi',
    myAppointments: 'Lịch hẹn',
    myFeedbacks: 'Ý kiến phản hồi',
    logoutConfirm: 'Bạn chắc chắn muốn đăng xuất?',
    signOff: 'Đăng xuất',
    cancel: 'Hủy bỏ',
    typeInPW: 'Nhập mật khẩu',
    // wsFooter.vue
    disclaimer: 'Tuyên bố miễn trừ trách nhiệm',
    about006Live: 'Về Live 06',
    Live006: 'Live 06',
    // wsScheduleTable.vue
    contests_name: 'Trận đấu/ Tên trận đấu',
    homeTeam: 'Đội nhà',
    guestTeam: 'Đội khách',
    streamer: 'Streamer',
    notStarted: 'Chưa bắt đầu',
    finished: 'Đã hoàn thành',
    living: 'Đang diễn ra',
    booked: 'Đã lên lịch hẹn',
    reservation: 'Lịch hẹn',
    football: 'Bóng đá',
    basketball: 'Bóng rổ',
    rest: 'Khác',
    tennis: 'Quần vợt',
    volleyball: 'billiards',
    billiards: 'Bida',
    badminton: 'Cầu lông',
    e_sports: 'E-sports',
    // wsAuthorized.vue
    retrievePassword: 'Lấy lại mật khẩu',
    toLogin: 'Trở về đăng nhập',
    registerAccounts: 'Đăng ký tài khoản',
    loginByAccounts: 'Tài khoản đăng nhập',
    loginByCodes: 'Mã xác minh',
    phoneText: 'Nhập số điện thoại',
    passwordText: 'Nhập mật khẩu',
    newPasswordText: 'Nhập mật khẩu mới',
    loginConfirmPsw: 'Nhập lại mật khẩu',
    picturesText: 'Nhập mã xác minh trong hình',
    codeText: 'Nhập mã xác minh',
    getCodeText: 'Gửi mã xác minh',
    timeReminder: 'Gửi lại mã xác minh sau s',
    forgetPassword: 'Quên mật khẩu',
    agreement: 'Đồng ý',
    registerAgreement: '《Thỏa thuận người dùng》',
    newRegister: 'Tạo tài khoản mới',
    phoneFormatReminder: 'Định dạng số điện thoại không chính xác',
    agreementReadReminder: 'Vui lòng đọc và đồng ý thỏa thuận người dùng mới',
    phoneNicknameReminder: 'Nhập tài khoản hoặc số điện thoại',
    passwordMatchReminder: 'Hai lần nhập mật khẩu không khớp',
    codeFormatReminder: 'Nhập 4 chữ số xác minh',
    //wsChatroom.vue
    systemMSG: 'Thông tin liên hệ:',
    associate: 'Trợ lý',
    manager: 'Quản lý',
    customer: 'CSKH',
    ordinary: "phổ biến",
    public: 'Hội viên thường',
    confirmSilent: 'Có cấm người dùng chat không',
    silent: 'Cấm chat',
    chatBoxText: 'Vui lòng nhập nội dung trò chuyện~',
    systemMaintenance: 'Hệ thống đang bảo trì',
    sendMSGtooFast: 'CSKH nói chuyện nhanh quá ạ (つД`)',
    failToSendMSG: 'Gửi tin nhắn thất bại',
    succeedToOperate: 'Thao tác thành công',
    send: 'Gửi',
    newMessages: 'thông tin mới',
    //userInfo.vue
    nickname: 'Biệt danh:',
    nicknameText: 'Nhập biệt danh',
    phoneNumber: 'Số điện thoại:',
    personalIntro: 'Giới thiệu bản thân:',
    maxLength: 'Không quá 200 từ',
    saveSubmission: 'Lưu',
    changeAvatar: 'Thay đổi hình đại diện',
    uploading: 'Đang tải lên...',
    uploaded: 'Tải lên thành công!',
    maxLengthNickname: ' Biệt danh không quá 12 từ',
    changed: 'Thay đổi thành công',
    uploadImage: 'Vui lòng tải lên hình ảnh(png,jpg,jpeg)',
    uploadImgLimit: 'Hình ảnh không quá 2MB!',
    noSign: 'Người đàn ông này lười biếng và không để lại gì~',
    //UserFollow.vue
    unFollow: 'Hủy follow',
    onfollow: 'Đi theo mỏ neo',
    streaming: 'Đang live',
    unFollowReminder: 'Sau khi hủy follow, bạn sẽ không nhận được thông báo liên quan đến streamer, bạn chắc chắn muốn hủy không?',
    thinkAgain: 'Tôi sẽ suy nghĩ thêm',
    //UserFeedbackCreate.vue UserFeedback.vue
    feedbackContent: 'Nội dung phản hồi',
    feedbackText: 'Phản hồi của bạn sẽ giúp chúng tôi trở nên hoàn thiện hơn, vui lòng mô tả chi tiết',
    uploadIMG: 'Tải lên hình ảnh',
    connection: 'Phương thức liên hệ',
    connectionReminder: 'Số điện thoại, Facebook, Telegram',
    submit: 'Gửi đi',
    submitted: 'Gửi thành công',
    feedbackReminder: 'Vui lòng điền nội dung phản hồi',
    feedbackImg: 'Hình ảnh phản hồi',
    nothing: 'Không',
    //UserAppointment.vue
    madeAnAppointment: 'Lịch hẹn thành công',
    //date: 'MM月DD日',
    myFiles: 'Thông tin của tôi',
    toFeedback: 'Gửi phản hồi',
    //Schedules.vue
    finishedList: 'Kết thúc trận đấu',
    noLiveYet: 'Tạm thời không có live',
    //Live.vue Lives.vue Reply.vue
    comprehensive: 'Tổng hợp',
    PremierLeague: 'Ngoại hạng Anh',
    LaLiga: 'VĐQG Tây Ban Nha',
    SerieA: 'VĐQG Ý',
    Bundesliga: 'VĐQG Đức',
    FrenchLeague: 'VĐQG Pháp',
    ChineseSuperLeague: 'Siêu cúp Trung Quốc',
    ChampionsLeague: 'Cúp EUFA',
    AFCChampionsLeagueCup: 'Cúp AFC',
    // follow: '关注',
    scan: 'Quét mã',
    addStreamers: 'Kết bạn với streamer',
    streamerSchedules: 'Lịch trình streamer',
    videoRecommends: 'Video giới thiệu',
    specialClaims: 'Tuyên bố đặc biệt:',
    specialClaimsContent1: 'Live 06 THỂ  là trang phát sóng trực tiếp toàn diện cho các sự kiện thể thao do bên thứ ba cung cấp. Chúng tôi không cung cấp bất kỳ video sự kiện trực tiếp độc quyền nào hoặc sản xuất bất kỳ dữ liệu sự kiện trực tiếp nào.',
    specialClaimsContent2: 'Người dùng vui lòng tuân thủ luật pháp và quy định hiện hành, vui lòng không sử dụng nó cho mục đích bất hợp pháp, nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với chúng tôi.',
    //Home.vue
    enterLiveRoom: 'Vào phòng live',
    HotLiving: ' Đang live',
    checkMore: 'Nhiều hơn',
    hotStreamers: 'Hot streamer',
    footballLiving: 'Live bóng đá',
    basketballLiving: 'Live bóng rổ',
    comprehensiveLiving: 'Live tổng hợp',
    enterLivingRoom: 'Vào phòng live',
    //App.vue
    goToScan: 'Quét mã tải app',
    adContent1: 'Tương tác không giới hạn, trò chuyện quên thời gian.',
    adContent2: 'Kết bạn qua nhắn thoại, chém gió vui hết xảy.',
    adContent3: 'Có hotgirls, hotboys livestream trực tiếp 24/7.',
    adContent4: 'Mang đến cho bạn những bình luận chuyên nghiệp nhất.',
    adContent5: 'Hàng loạt chương trình thể thao phát sóng trực tiếp với độ nét cực cao cùng các chuyên.',
    adContent6: 'gia bình luận viên chuyên nghiệp.',
    //About.vue
    appIntro1: '06 THỂ là trang chuyên phát sóng trực tiếp các trận đấu thể thao bóng đá, bóng rổ.',
    appIntro2: '06 THỂ cam kết không ngừng cải thiện trải nghiệm người dùng, phấn đấu trở thành trang phát sóng bóng đá, bóng rổ hàng đầu tại Việt Nam, đồng thời góp phần hồi sinh bóng đá, bóng rổ trong nước.',
    appIntro3: '06 THỂ cung cấp cho bạn:',
    appIntro4: 'Dịch vụ bình luận video ổn định và phổ biến hàng đầu;',
    appIntro5: 'Phát sóng trực tiếp toàn diện các trận đấu hot cùng độ nét cao;',
    appIntro6: 'Bình luận trực tiếp chuyên nghiệp, khách quan;',
    appIntro7: 'Thông tin chi tiết và đầy đủ;',
    //Privacy.vue
    privacyAgreement: 'Chính sách bảo mật',
    privacyContent1: 'Chính sách quyền riêng tư này mô tả các chính sách và thực tiễn liên quan đến quyền riêng tư của công ty, bao gồm cách chúng tôi thu thập, sử dụng, xử lý, lưu trữ và/hoặc tiết lộ thông tin cá nhân về bạn được thu thập thông qua trang web của công ty. Vui lòng đọc kỹ Chính sách bảo mật của chúng tôi.',
    privacyContent2: '1. Công ty thu thập thông tin cá nhân của bạn như thế nào？',
    privacyContent3: 'Thông tin cá nhân là dữ liệu duy nhất có thể được sử dụng để xác thực hoặc liên hệ với người dùng.',
    privacyContent4: 'Khi sử dụng trang web công ty xem các video thể thao, chúng tôi sẽ thu thập thông tin cá nhân trong quá trình đăng ký người dùng, chẳng hạn như: số điện thoại. Để bảo vệ quyền riêng tư cá nhân, người dùng không nên cung cấp bất kỳ thông tin nào khác ngoại trừ những thông tin được công ty yêu cầu.',
    privacyContent5: '2. Công ty sử dụng thông tin cá nhân của người dùng như thế nào?',
    privacyContent6: '2.1.Thực hiện chức năng lấy lại mật khẩu thông qua thông tin cá nhân của người dùng.',
    privacyContent7: '2.2.Công ty sẽ không cung cấp, bán, cho thuê, chia sẻ hoặc trao đổi thông tin cá nhân của người dùng cho bất kỳ bên thứ ba không liên quan nào,ngoại trừ trường hợp công ty được tổ chức lại, sáp nhập, bán và chuyển giao cho bên thứ ba có liên quan hoặc được sự cho phép của người dùng và bên thứ ba và công ty cung cấp dịch vụ cho bạn một cách riêng lẻ hoặc chung. Sau khi kết thúc dịch vụ, họ sẽ bị cấm truy cập tất cả thông tin người dùng trước đó.',
    privacyContent8: '3. Bảo mật thông tin cá nhân',
    privacyContent9: 'Bảo mật dữ liệu cá nhân của người dùng là điều quan trọng đối với chúng tôi. Khi người dùng đăng ký và nhập dữ liệu cá nhân trên trang web công ty, chúng tôi sẽ mã hóa thông tin này bằng công nghệ bảo mật (SSL).',
    privacyContent10: 'Chúng tôi sẽ bảo vệ thông tin người dùng đã gửi dựa trên các tiêu chuẩn ngành được chấp nhận chung (chẳng hạn như tường lửa, mã hóa và các yêu cầu pháp lý về quyền riêng tư dữ liệu) trong giai đoạn truyền dữ liệu và lưu trữ dữ liệu.',
    privacyContent11: 'Tuy nhiên, không có phương thức truyền Internet hoặc lưu trữ điện tử nào an toàn 100%. Do đó, chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của thông tin.',
    privacyContent12: '4. Công ty sẽ lưu giữ thông tin cá nhân trong bao lâu?',
    privacyContent13: 'Nói chung, công ty sẽ chỉ lưu giữ thông tin cá nhân của bạn trong khoảng thời gian cần thiết để hoàn thành mục đích thu thập và sẽ tuân thủ thời gian lưu giữ dữ liệu theo quy định của pháp luật hiện hành.',
    privacyContent14: '5. Tuyên bố miễn trừ trách nhiệm pháp luật',
    privacyContent15: 'Công ty có quyền công khai thông tin người dùng khi luật pháp yêu cầu và để bảo vệ các quyền hợp pháp của công ty và/hoặc để tuân thủ các thủ tục tư pháp, lệnh của tòa án hoặc thủ tục pháp lý áp dụng cho trang web công ty.',
    privacyContent16: 'Chúng tôi có thể tiết lộ thông tin về người dùng nếu việc này là cần thiết và hợp lý để thực thi các điều khoản và điều kiện hoặc bảo vệ hoạt động kinh doanh của công ty.',
    privacyContent17: '6. Các thay đổi đối với Chính sách quyền riêng tư',
    privacyContent18: 'Nếu quyết định thay đổi chính sách bảo mật, chúng tôi sẽ thông báo trên trang web hoặc nơi chúng tôi cho là phù hợp, để người dùng nắm rõ cách chúng tôi thu thập, sử dụng cũng như ai có quyền truy cập và trong trường hợp nào chúng tôi sẽ công khai các thông tin này.',
    privacyContent19: 'Công ty có quyền sửa đổi chính sách này tại bất kỳ thời điểm nào, vì vậy vui lòng thường xuyên theo dõi tin tức. Công ty sẽ công bố các thay đổi quan trọng trên trang web.',
    //Disclaimer.vue
    disclaimerAndUserAgreement: 'Thỏa thuận người dùng và tuyên bố miễn chịu trách nhiệm',
    //news
    news:'Tin Tức',
    released:'thời gian phát hành',
}