<template>
	<div class="page-user-schedule">
		<div class="schedule" v-for="item in appointments" :key="item.date">
			<div class="schedule-header">{{ item.date }}</div>
			<div class="schedule-body">
				<ws-schedule-table size="small" :lists="item.lists" @appointment="onAppointment" />
			</div>
		</div>
		<div class="nodata" v-if="appointments.length === 0"><img src="../assets/img/noappointment.png" alt="" /></div>
	</div>
</template>

<script>
import moment from 'moment';
import wsScheduleTable from '@/components/wsScheduleTable';
export default {
	name: 'UserAppointment',
	components: {
		wsScheduleTable
	},
	data() {
		return {
			appointments: []
		};
	},
	mounted() {
		this.getAppointments();
	},
	methods: {
		async getAppointments() {
			const response = await this.$api.get('/web/appointment/list');
			if (response && response.code == 200) {
				const appointments = [];
				Object.keys(response.data).forEach(date => {
					let lists = response.data[date] || [];
					if (Array.isArray(lists) && lists.length) {
						appointments.push({ date, lists, key: moment(date, 'MM月DD日').unix() / 1000 });
					}
				});
				this.appointments = appointments.sort((a, b) => a.key - b.key);
			}
		},
		async onAppointment(match) {
			const response = await this.$api.post(`/web/appointment`, {
				opt_type: match.is_reservation == 1 ? 0 : 1,
				tournament_id: match.tournament_id,
				tournament_type: match.tournament_type
			});
			if (response && response.code == 200) {
				this.$message.success(match.is_reservation == 1 ? this.$t('succeedToOperate') : this.$t('madeAnAppointment'));
				Object.assign(match, {
					is_reservation: match.is_reservation == 1 ? 0 : 1
				});
				// this.getSchedules();
			}
		}
	}
};
</script>

<style lang="less">
.page-user-schedule {
	.schedule {
		margin-bottom: 20px;

		&-header {
			height: 49px;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0 0 0 24px;
			background: var(--primary-color);
			font-size: 20px;
			color: #ffffff;

			&::before {
				content: inherit;
			}
		}

		&-body {
			thead {
				display: none;
			}
		}
	}
}
</style>
