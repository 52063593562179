
<template>
	<a target="_blank" :href="item.href" class="match-item">
		<img class="cover" :src="item.cover || '0'" @error="onCoverError" />
		<div class="info">
			<div class="title">{{ item.title }}</div>
			<div class="meta">
				<img class="avatar" :src="item.face || '0'" @error="onAvatarError" />
				<span class="nickname">{{ item.username }}</span>
				<!-- <span style="flex:1"></span> -->
				<span class="hot" v-if="!!item.viewer">{{ item.viewer | kindlyNumber }}</span>
			</div>
		</div>
	</a>
</template>

<script>
export default {
	name: 'wsMatchItem',
	props: {
		item: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {};
	}
};
</script>