import Vue from 'vue'
import VueI18n from 'vue-i18n'
import viLang from './vi'
import zhLang from './zh'
Vue.use(VueI18n)
const messages = {
    vi:{
        ...viLang
    },
    zh:{
        ...zhLang
    }
}
const i18n = new VueI18n({
    locale: 'vi', // 设置语种
    messages,
})
export default i18n