<template>
	<div class="chat">
		<div class="chat-header">
			<span class="notice">{{ $t('systemMSG') }}</span>
			<span>{{ match.room_notice }}</span>
		</div>
		<ul class="chat-body" ref="message" @scroll="scroll">
			<li
				:class="[
					'message',
					'message-' + msg.content_type,
					{
						'message-role-assistant': msg.is_assistant,
						'message-role-manage': msg.is_manage,
						'message-role-service': msg.is_service,
						'message-role-anchor': msg.is_anchor,
						'message-role-self': msg.is_self
					}
				]"
				v-for="msg in messages"
				:key="msg.mid"
			>
				<span v-if="msg.content_type == 'system'"></span>
				<span class="role" v-else-if="msg.is_assistant">{{ $t('associate') }}</span>
				<span class="role" v-else-if="msg.is_manage">{{ $t('manager') }}</span>
				<span class="role" v-else-if="msg.is_service">{{ $t('customer') }}</span>
				<span class="role" v-else-if="msg.is_anchor">{{ $t('streamer') }}</span>
				<span class="role" v-else-if="msg.is_self">{{ $t('ordinary') }}</span>
				<span class="role" v-else :style="{ backgroundColor: msg.color }">{{ $t('ordinary') }}</span>
				<!-- <a-dropdown>
					<a-menu slot="overlay" @click="e => onMute(msg, e)">
						<a-menu-item key="forbidden">禁言用户</a-menu-item>
						<a-menu-item key="delete">删除消息</a-menu-item>
					</a-menu>
					<span class="nickname cursor" v-if="msg.role > 0 || msg.is_self">{{ msg.from_name }}：</span>
					<span class="nickname cursor" v-else :style="{ color: msg.color }">{{ msg.from_name }}：</span>
				</a-dropdown> -->
				<a-popconfirm :title="`“${msg.from_name}”？`" :ok-text="$t('silent')" :cancel-text="$t('cancel')" v-if="msg.content_type == 'txt' && msg.mutable" @confirm="onMute(msg)">
					<span class="nickname cursor" v-if="msg.role > 0 || msg.is_self">{{ msg.from_name }}：</span>
					<span class="nickname cursor" v-else :style="{ color: msg.color }">{{ msg.from_name }}：</span>
				</a-popconfirm>
				<span class="nickname" v-else-if="msg.content_type == 'txt' && (msg.role > 0 || msg.is_self)">{{ msg.from_name }}：</span>
				<span class="nickname" v-else-if="msg.content_type == 'txt'" :style="{ color: msg.color }">{{ msg.from_name }}：</span>
				<span class="message-system" v-if="msg.content_type == 'system'">{{ msg.content }}</span>
				<span class="message-content" v-else v-html="msg.html"></span>
				<!-- {{ msg.content }} -->
			</li>
			<li style="flex: 1"></li>
		</ul>
		 <!-- <div class="newMsg" v-show="moreNews" @click="scrollBottom">
            <img src="../assets/img/down.png" alt="">
           {{ $t('newMessages') }}
        </div> -->
		<div class="chat-editor">
			<textarea
				class="editor"
				:placeholder="match.room_status == 1 ? this.$t('chatBoxText')  : this.$t('systemMaintenance')"
				v-model="chatroom.text"
				@keypress.enter.exact="onSend"
				:resize="false"
				ref="textarea"
				:disabled="match.room_status != 1"
			></textarea>
			<div class="actions">
				<!-- <a href="javascript:;" :class="['ban', chatroom.shield ? 'active' : '']" @click="chatroom.shield = !chatroom.shield"></a> -->
				<!-- <a href="javascript:;" class="emjio"></a> -->
				<a-popover v-model="popover" trigger="click" overlay-class-name="popover-emoticon">
					<div slot="content" class="emoticons">
						<div class="emoticon" v-for="e in EMOTICONS" :key="e" @click="insertEmoticon(e)">
							<img :src="`//06thethao.oss-cn-hongkong.aliyuncs.com/emoticons/${e}.png?x-oss-process=image/quality,q_50`" :alt="e" />
						</div>
					</div>
					<a href="javascript:;" :class="['emjio', popover ? 'active' : '']"></a>
				</a-popover>
				<span style="flex: 1"></span>
				<a href="javascript:;" :class="['send', match.room_status == 1 && chatroom.text ? 'active' : '']" @click="onSend">{{$t('send')}}</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { EMOTICONS, TEXT_COLOR } from '@/utils/constants.js';

const uColorMap = {};

export default {
	name: 'wsChatroom',
	props: {
		anchor_uid: [String, Number],
		match: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			EMOTICONS,
			uid: '',
			moreNews:false,
            moreNewsHeight:0,
            toTop:0,
			mutes: [],
			muteMids: [],
			chatroom: {
				loading: false,
				shield: false,
				text: '',
				manage: [],
				assistant: [],
				service: [],
				messages: []
			},
			popover: false
		};
	},
	computed: {
		...mapState(['token', 'minfo']),
		messages() {
			const { assistant, service, manage, messages, shield } = this.chatroom;
			const vips = [...assistant, ...service, ...manage];
			const role = vips.includes(this.minfo.uid) ? 2 : this.minfo.uid == this.uid ? 1 : 0;
			return messages
				.filter(v => {
					v.from = parseInt(v.from);
					return !this.muteMids.includes(v.mid) && !this.mutes.includes(v.from) && v.to == this.uid && (shield ? v.content_type == 'txt' : true);
				})
				.map(v => {
					v.from = parseInt(v.from);
					v.to = parseInt(v.to);
					v.is_assistant = assistant.includes(v.from);
					v.is_service = service.includes(v.from);
					v.is_manage = manage.includes(v.from);
					v.is_self = v.from == this.minfo.uid;
					v.is_anchor = v.from == this.uid;
					v.role = v.is_assistant || v.is_service || v.is_manage ? 2 : v.is_anchor ? 1 : 0;
					v.mutable = role - v.role > 0;
					uColorMap[v.from] = uColorMap[v.from] || TEXT_COLOR[Math.floor(Math.random() * TEXT_COLOR.length)];
					v.color = uColorMap[v.from];
					v.html = v.content
						.replace(/</g, '&lt;')
						.replace(/>/g, '&gt;')
						.replace(/\[[^\[\]]*?\]/g, function($1) {
							if (EMOTICONS.includes($1)) {
								return `<img class="emoticon" src="//06thethao.oss-cn-hongkong.aliyuncs.com/emoticons/${$1}.png" alt="${$1}" />`;
							}
							return $1;
						});
					return v;
				});
		},
		isManager() {
			const { assistant, service, manage } = this.chatroom;
			const muid = this.minfo.uid;
			if (muid) {
				return [this.uid, ...assistant, ...service, ...manage].includes(muid);
			}
			return false;
		}
	},
	watch: {
		'messages.length'() {
			if((this.moreNewsHeight -  this.toTop)>410){
                this.moreNews = true
            }else{
                this.$nextTick(this.scrollBottom);
            }
		}
	},
	created() {
		this.$bus.$on('ws.reconnect', this.getOfflineMessage);
	},
	beforeDestroy() {
		this.$bus.$off('ws.reconnect', this.getOfflineMessage);
	},
	methods: {
		scroll(e){
            this.toTop = this.$refs.message.scrollTop;
            if((this.moreNewsHeight -  this.toTop)<=410){
                this.moreNews = false;
            }
        },
		init(uid) {
			if (uid) {
				this.uid = uid;
				this.enterChatroom();
			}
		},
		async getOfflineMessage() {
			if (!this.uid) {
				return;
			}
			const response = await this.$api.get(`/web/room_msg/${this.uid}`);
			if (response.code == 200) {
				const { assistant, manage, service, data } = response.data;
				this.chatroom.assistant = assistant;
				this.chatroom.manage = manage;
				this.chatroom.service = service;
				this.chatroom.messages = data.map(v => {
					v.content_type = 'txt';
					v.to = this.uid;
					v.from_name = v.nickname;
					return v;
				});
				this.$nextTick(this.scrollBottom);
			}
		},
		enterChatroom() {
			this.getOfflineMessage();
			this.$bus.$off('ws.message', this.onMessage);
			this.$bus.$on('ws.message', this.onMessage);
			this.$connect.subscribe(this.uid);
		},
		onMessage(res) {
			this.moreNewsHeight = this.$refs.message.scrollHeight
			if (res.channel == `room-${this.uid}`) {
				if (res.event == 'room') {
					this.chatroom.messages.push(res.data);
				}
			}
			if (res.event == 'delRoomMsg') {
				if (res.data.uid) {
					this.mutes.push(parseInt(res.data.uid));
					console.info('mutes', this.mutes)
				} else if (res.data.room_id == this.uid && Array.isArray(res.data.mid)) {
					let mids = res.data.mid.map(v => parseInt(v));
					this.muteMids.push(...mids);
				}
			}
		},
		async onSend(e) {
			e && e.preventDefault();
			if (!this.token) {
				return this.$bus.$emit('login');
			}
			const { text } = this.chatroom;
			if (text) {
				if (this.chatroom.loading) {
					return this.$message.error(this.$t('sendMSGtooFast'));
				}
				if (this.match.room_status == 1) {
					this.chatroom.loading = true;
					const response = await this.$api.post('/web/send_room', { to: this.uid, content: text, content_type: 'txt' }).catch(e => {
						this.chatroom.loading = false;
					});
					if (response && response.code == 200) {
						this.chatroom.text = '';
					} else {
						this.$message.error(response.msg || this.$t('failToSendMSG'));
					}
					this.$nextTick(() => {
						this.chatroom.loading = false;
					});
				} else {
					return this.$message.error(this.$t('succeedToOperate'));
				}
			}
		},
		scrollBottom() {
			this.$refs.message.scrollTo({
				top: 99999,
				behavior: 'smooth'
			});
		},
		insertEmoticon(e) {
			const el = this.$refs.textarea;
			const text = this.chatroom.text;
			const ntext = text.substring(0, el.selectionStart) + e + text.substring(el.selectionEnd);
			const n = el.selectionStart + e.length;
			this.chatroom.text = ntext;
			this.$nextTick(() => {
				this.popover = false;
				el.focus();
				el.selectionStart = n;
				el.selectionEnd = n;
			});
		},
		async onMute(msg) {
			// console.info('onMute', msg, e);
			const e = { key: 'forbidden' };
			if (msg.mutable) {
				if (e.key == 'delete') {
					const response = await this.$api.post('/web/del_room_msg', { room_id: msg.to, member_id: msg.to, mid: msg.mid });
					if (response && response.code == 200) {
						// this.mutes.push(msg.from);
						this.muteMids.push(msg.mid);
						this.$message.success(this.$t('succeedToOperate'));
					}
				} else if (e.key == 'forbidden') {
					const response = await this.$api.post('/web/forbidden_speech', { room_id: msg.to, uid: msg.from });
					if (response && response.code == 200) {
						this.mutes.push(msg.from);
						this.$message.success(this.$t('succeedToOperate'));
					}
				}
			}
		}
	}
};
</script>

<style lang="less">
.popover-emoticon {
	.ant-popover-inner-content {
		padding: 4px;
	}
}

.nickname.cursor {
	cursor: pointer;
}
.emoticons {
	width: 320px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-top: #e5e5e5 1px solid;
	border-left: #e5e5e5 1px solid;
	.emoticon {
		padding: 2px;
		border-right: #e5e5e5 1px solid;
		border-bottom: #e5e5e5 1px solid;
		cursor: pointer;
		img {
			display: block;
			width: 24px;
			height: 24px;
		}
	}
}
</style>
