<template>
	<header class="header">
		<div class="wrapper">
			<a href="/" class="logo"><img src="@/assets/img/logo.png" /></a>
			<nav class="menu">
				<a :class="[routeName == 'home' ? 'active' : '']" href="/"> {{ $t("frontPage") }}</a>
				<a :class="[routeName == 'lives' ? 'active' : '']" href="/lives/1">{{ $t("allLive") }}</a>
				<a :class="[routeName == 'schedules' ? 'active' : '']" href="/schedules">{{ $t("schedules") }}</a>
				<a :class="[routeName == 'news' ? 'active' : '']" href="/news">{{ $t("news") }}</a>
				<a :class="[routeName == 'app' ? 'active' : '']" href="/app">{{ $t("app") }}</a>
			</nav>

			<nav class="user" v-if="isDev" @click="changeLocale" style="color:red;">
				<ws-icon></ws-icon>
				语言切换调试，当前{{ this.$i18n.locale }}
			</nav>
			<nav class="user" v-if="minfo.uid">
				<a href="/anchor/" class="login" target="_blank"
					v-if="minfo && minfo.role && (minfo.role == 1 && minfo.uid == 133 || [3, 9].includes(minfo.role))">
					{{ $t("startLive") }}
				</a>
				<div class="userinfo">
					<img class="avatar" :src="minfo.avatar" :alt="minfo.nickname" @error="onAvatarError" />
					<span>{{ minfo.nickname }}</span>

					<div class="userinfo-popover">
						<div class="userinfo-popover-content">
							<div class="info">
								<img class="avatar" :src="minfo.avatar" :alt="minfo.nickname" @error="onAvatarError" />
								<span class="name">{{ minfo.nickname }}</span>
								<a href="javascript:;" class="button" @click="onLogout">{{ $t("logout") }}</a>
							</div>
							<div class="sign">{{ minfo.sign }}</div>
							<div class="tabs">
								<router-link to="/user/info" class="tabs-item icon-user">{{ $t("personalCenter")
								}}</router-link>
								<router-link to="/user/follow" class="tabs-item icon-follow">{{ $t("myFollows")
								}}</router-link>
								<router-link to="/user/appointment" class="tabs-item icon-appointment">{{
									$t("myAppointments") }}</router-link>
								<router-link to="/user/feedback" class="tabs-item icon-feedback">{{ $t("myFeedbacks")
								}}</router-link>
							</div>
						</div>
					</div>
				</div>
			</nav>
			<nav class="user" v-else>

				<img class="icon-auth" src="../assets/img/icon-auth.png" style="margin-left: 20px;" />
				<a href="javascript:;" class="login" @click="toLogin">{{ $t("login") }}</a>
				<span>|</span>
				<a href="javascript:;" class="register" @click="toRegister">{{ $t("register") }}</a>
			</nav>
		</div>
		<ws-authorize :type.sync="modal.type" v-model="modal.visible"></ws-authorize>
	</header>
</template>

<script>
import { mapState } from 'vuex';
import wsAuthorize from '@/components/wsAuthorize.vue';
import wsIcon from '@/components/wsIcon.vue';
import { AUTHORIZE_TYPE } from '@/utils/constants.js';
import { Modal } from 'ant-design-vue';

export default {
	name: 'wsHeader',
	components: {
		wsAuthorize,
		wsIcon
	},
	data() {
		return {
			AUTHORIZE_TYPE,
			modal: {
				type: AUTHORIZE_TYPE.LOGIN_PASSWORD,
				visible: false
			}
		};
	},
	computed: {
		...mapState(['minfo']),
		routeName() {
			return this.$route.name.toLowerCase();
		}
	},
	created() {
		this.$bus.$on('login', this.toLogin);
	},
	methods: {
		changeLocale() {
			this.$i18n.locale == 'zh' ? this.$i18n.locale = 'vi' : this.$i18n.locale = 'zh'
		},
		toLogin() {
			this.modal.type = AUTHORIZE_TYPE.LOGIN_PASSWORD;
			this.modal.visible = true;
		},
		toRegister() {
			this.modal.type = AUTHORIZE_TYPE.REGISTER;
			this.modal.visible = true;
		},
		onLogout() {
			this.$modal.confirm({
				title: this.$t("logoutConfirm"),
				okType: 'danger',
				okText: this.$t("signOff"),
				cancelText: this.$t("cancel"),
				onOk: async () => {
					await this.$api.post('/web/login_out');
					this.$storage.clear();
					location.href = '/';
				}
			});
		}
	}
};
</script>

<style lang="less"></style>
