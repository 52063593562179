<template>
  <a-form-model :model="formdata" class="form-feedback">
    <a-form-model-item :label="this.$t('feedbackContent')">
      <a-input
        v-model="formdata.content"
        type="textarea"
        :rows="10"
        :maxLength="500"
        :placeholder="$t('feedbackText')"
      />
    </a-form-model-item>
    <a-form-model-item :label="this.$t('uploadIMG')">
      <div class="uploader">
        <a-upload
          name="file"
          :before-upload="(f) => onBeforeUpload(f, idx)"
          v-for="(img, idx) in formdata.imgs"
          :key="idx"
        >
          <a-avatar shape="square" :size="120" :src="img" />
        </a-upload>
        <a-upload
          name="file"
          :before-upload="(f) => onBeforeUpload(f, -1)"
          v-if="formdata.imgs.length < 3"
          ><a-avatar shape="square" :size="120" :src="holder"
        /></a-upload>
      </div>
    </a-form-model-item>
    <a-form-model-item :label="$t('connection')"
      ><a-input
        v-model="formdata.contact"
        :placeholder="this.$t('connectionReminder')"
    /></a-form-model-item>
    <a-form-model-item
      ><button class="button" type="button" @click="onSubmit" :disabled="submitBled">
        {{ $t("submit") }}
      </button></a-form-model-item
    >
  </a-form-model>
</template>

<script>
import OSS from "ali-oss";
const holder =
  "https://vntyzb.oss-cn-hongkong.aliyuncs.com/web/feedback/default_uploader.png";
export default {
  name: "UserFeedback",
  data() {
    return {
      holder,
      formdata: {
        content: "",
        imgs: [],
        contact: "",
      },
      submitBled:false,
    };
  },
  methods: {
    async onBeforeUpload(file, index) {
      if (!/image\//.test(file.type)) {
        this.$message.error(this.$t("uploadImage"));
		return Promise.reject(false);
      }
      if (file.size > (1024 * 1024 * 2)) {
        this.$message.error(this.$t("uploadImgLimit"));
		return Promise.reject(false);
      }
      this.$loading();
	  
      const response = await this.$api.get("/ali/token");
	  console.log(2222);
      if (response && response.code == 200) {
        const {
          url,
          accessKeyId,
          accessKeySecret,
          securityToken,
          bucketName,
          endpoint,
        } = response.data;
        const client = new OSS({
          endpoint,
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
          bucket: bucketName,
          secure: /^https/.test(location.href),
        });
        const result = await client.put(
          "web/feedback/" + Date.now() + ".png",
          file
        );
        if (result && result.name) {
          if (index === -1) {
            this.formdata.imgs.push(url + "/" + result.name);
          } else {
            this.$set(this.formdata.imgs, index, url + "/" + result.name);
          }
        }
      }
      this.$nextTick(() => {
        this.$loadend();
      });
      return Promise.reject(false);
    },
    async onSubmit() {
      this.submitBled = true
      const { content, imgs, contact } = this.formdata;
      if (content.replace(/\s/g, "")) {
        const response = await this.$api.post("/web/feedback", {
          content,
          imgs: imgs.join(","),
          contact,
        });
        if (response && response.code == 200) {
          this.$message.success(this.$t("submitted"));
          this.$router.replace("/user/feedback");
        }
        this.submitBled = false
     
      } else {
        this.$message.error(this.$t("feedbackReminder"));
      }
    },
  },
};
</script>

<style lang="less">
.form-feedback {
  .ant-form-item-label {
    label {
      padding-left: 18px;
      font-size: 18px;
      color: #26293a;
    }
  }

  .uploader {
    display: flex;
    cursor: pointer;
    > span {
      margin-right: 34px;
    }
  }

  .button {
    padding: 0 32px;
  }
}
</style>
